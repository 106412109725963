import React from 'react';
import styles from './SubCategoryList.module.scss';
import { SubCategory } from '../SubCategoryItem/SubCategoryItem';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../../config/routes/routes';
import DropdownItem from '../DropdownItem/DropdownItem';

type Props = {
  subCategory: SubCategory;
  onTitleClick: () => void;
};

const SubCategoryList = ({ subCategory, onTitleClick }: Props) => {
  const navigate = useNavigate();

  return (
    <>
      <DropdownItem
        nameClassName={styles.headerName}
        onClick={onTitleClick}
        name={subCategory.name}
        icon={subCategory.icon}
        isBack
        darken
      />

      {subCategory.items?.map((item) => (
        <DropdownItem
          key={item.name}
          onClick={() => navigate(routes.products)}
          name={item.name}
          showChevron={false}
          darken
        />
      ))}
    </>
  );
};

export default SubCategoryList;
