import React from 'react';
import Section from '../Section/Section';
import ManufacturerCard from './ManufacturerCard/ManufacturerCard';
import styles from './Manufacturers.module.scss';
import { useRecoilValue } from 'recoil';
import { translationsState } from '../../../atoms/translation/translation.atom';
import { translate } from '../../../utils/translation/translation';

export const Manufacturers = () => {
  const translations = useRecoilValue(translationsState);

  return (
    <Section
      title={translate(translations, 'MANUFACTURERS.TITLE')}
      className={styles.cardsContainer}
      titleClassName={styles.title}
    >
      <ManufacturerCard title={'LOGO'} />
      <ManufacturerCard title={'LOGO'} />
      <ManufacturerCard title={'LOGO'} />
      <ManufacturerCard title={'LOGO'} />
      <ManufacturerCard title={'LOGO'} />
      <ManufacturerCard title={'LOGO'} />
    </Section>
  );
};

export default Manufacturers;
