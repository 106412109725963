import React from 'react';
import { Box, Typography } from '@mui/material';
import styles from './Community.module.scss';
import facebookIcon from '../../../assets/socials/facebook.svg';
import youtubeIcon from '../../../assets/socials/youtube.svg';
import instagramIcon from '../../../assets/socials/instagram.svg';
import linkedinIcon from '../../../assets/socials/linkedin.svg';
import tiktokIcon from '../../../assets/socials/tik-tok.svg';
import SocialCard from './SocialCard/SocialCard';
import { useRecoilValue } from 'recoil';
import { translationsState } from '../../../atoms/translation/translation.atom';
import { translate } from '../../../utils/translation/translation';

export const Community = () => {
  const translations = useRecoilValue(translationsState);

  return (
    <Box className={styles.communityContainer}>
      <Box className={styles.innerContainer}>
        <Box className={styles.titleContainer}>
          <Typography className={styles.title}>
            {translate(translations, 'COMMUNITY.TITLE')}
          </Typography>
          <Typography className={styles.subtitle}>
            {translate(translations, 'COMMUNITY.SUBTITLE')}
          </Typography>
        </Box>
        <Box className={styles.cardsContainer}>
          <SocialCard
            image={facebookIcon}
            name={translate(translations, 'COMMUNITY.FACEBOOK_NAME')}
            link={translate(translations, 'COMMUNITY.FACEBOOK_LINK')}
          />
          <SocialCard
            image={youtubeIcon}
            name={translate(translations, 'COMMUNITY.YOUTUBE_NAME')}
            link={translate(translations, 'COMMUNITY.YOUTUBE_LINK')}
          />
          <SocialCard
            image={instagramIcon}
            name={translate(translations, 'COMMUNITY.INSTAGRAM_NAME')}
            link={translate(translations, 'COMMUNITY.INSTAGRAM_LINK')}
          />
          <SocialCard
            image={linkedinIcon}
            name={translate(translations, 'COMMUNITY.LINKEDIN_NAME')}
            link={translate(translations, 'COMMUNITY.LINKEDIN_LINK')}
          />
          <SocialCard
            image={tiktokIcon}
            name={translate(translations, 'COMMUNITY.TIKTOK_NAME')}
            link={translate(translations, 'COMMUNITY.TIKTOK_LINK')}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Community;
