import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import SubCategoryItem, {
  SubCategory,
} from '../SubCategoryItem/SubCategoryItem';
import { routes } from '../../../../config/routes/routes';
import DropdownItem from '../DropdownItem/DropdownItem';

export type Category = {
  id: number;
  name: string;
  icon: string;
  subCategories?: SubCategory[];
};

type Props = {
  category: Category;
  onClick: () => void;
  isOpen: boolean;
  onSubCategoryClick?: (id: number) => void;
};

const CategoryItem = ({
  category,
  onClick,
  isOpen,
  onSubCategoryClick,
}: Props) => {
  const navigate = useNavigate();

  const hasSubCategories = useMemo(
    () => !!category.subCategories?.length,
    [category.subCategories],
  );

  return (
    <>
      <DropdownItem
        name={category.name}
        icon={category.icon}
        onClick={() =>
          hasSubCategories ? onClick() : navigate(routes.products)
        }
        showChevron={hasSubCategories}
        isOpen={isOpen}
        darken={isOpen}
      />
      {isOpen &&
        hasSubCategories &&
        category.subCategories?.map((subCategory) => (
          <SubCategoryItem
            onClick={
              onSubCategoryClick
                ? () => onSubCategoryClick(subCategory.id)
                : undefined
            }
            key={subCategory.id}
            subCategory={subCategory}
          />
        ))}
    </>
  );
};

export default CategoryItem;
