import React, { useRef, useState } from 'react';
import { Box, Menu, Typography } from '@mui/material';
import styles from './CartInfo.module.scss';
import { translate } from '../../../../../utils/translation/translation';
import TopNavigationIconItem from '../TopNavigationIconItem/TopNavigationIconItem';
import cartIcon from '../../../../../assets/icons/cart.svg';
import { useRecoilValue } from 'recoil';
import { translationsState } from '../../../../../atoms/translation/translation.atom';
import TotalAmount from '../TotalAmount/TotalAmount';
import { useWindowWidth } from '@react-hook/window-size';
import Button, { ButtonVariant } from '../../../../common/Button/Button';
import { NumericFormat } from 'react-number-format';
import { routes } from '../../../../../config/routes/routes';
import { useNavigate } from 'react-router-dom';

const MOBILE_BREAKPOINT = 900;

const fakeCartItems = [
  {
    id: 1,
    image: 'https://media.autodoc.de/360_photos/16144297/preview.jpg',
    name: 'KAVO PARTS Alyvos filtras',
    price: 99.99,
    quantity: 2,
  },
  {
    id: 2,
    image: 'https://media.autodoc.de/360_photos/16144297/preview.jpg',
    name: 'KAVO PARTS Alyvos filtras',
    price: 1099,
    quantity: 2,
  },
  {
    id: 3,
    image: 'https://media.autodoc.de/360_photos/16144297/preview.jpg',
    name: 'KAVO PARTS Alyvos filtras',
    price: 1099,
    quantity: 2,
  },
  {
    id: 4,
    image: 'https://media.autodoc.de/360_photos/16144297/preview.jpg',
    name: 'KAVO PARTS Alyvos filtras',
    price: 1099,
    quantity: 2,
  },
  {
    id: 5,
    image: 'https://media.autodoc.de/360_photos/16144297/preview.jpg',
    name: 'KAVO PARTS Alyvos filtras',
    price: 1099,
    quantity: 2,
  },
  {
    id: 6,
    image: 'https://media.autodoc.de/360_photos/16144297/preview.jpg',
    name: 'KAVO PARTS Alyvos filtras',
    price: 1099,
    quantity: 2,
  },
  {
    id: 7,
    image: 'https://media.autodoc.de/360_photos/16144297/preview.jpg',
    name: 'KAVO PARTS Alyvos filtras',
    price: 1099,
    quantity: 2,
  },
  {
    id: 8,
    image: 'https://media.autodoc.de/360_photos/16144297/preview.jpg',
    name: 'KAVO PARTS Alyvos filtras',
    price: 1099,
    quantity: 2,
  },
];

export const CartInfo = () => {
  const translations = useRecoilValue(translationsState);
  const navigate = useNavigate();
  const width = useWindowWidth();
  const containerRef = useRef();
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <Box
        ref={containerRef}
        className={styles.cartInfoContainer}
        onClick={() => setMenuOpen(true)}
      >
        <TopNavigationIconItem
          icon={cartIcon}
          title={translate(translations, 'CART_INFO.ITEMS')}
          showTitle={width >= MOBILE_BREAKPOINT}
          subtitle="#74-3088"
          showSubtitle={width >= MOBILE_BREAKPOINT}
          total={101}
        />
        {width >= MOBILE_BREAKPOINT && <TotalAmount amount={9999.99} />}
      </Box>
      <Menu
        anchorEl={containerRef.current}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        className={styles.menu}
      >
        <Box className={styles.cartItemContainer}>
          {fakeCartItems.map((cartItem) => (
            <Box
              onClick={() => navigate(routes.product)}
              key={cartItem.id}
              className={styles.cartItem}
            >
              <img src={cartItem.image} />

              <Box className={styles.cartItemInfo}>
                <Typography className={styles.cartItemName}>
                  {cartItem.name}
                </Typography>
                <Box className={styles.cartItemPriceContainer}>
                  <NumericFormat
                    value={cartItem.price}
                    decimalScale={2}
                    thousandsGroupStyle="lakh"
                    thousandSeparator=" "
                    decimalSeparator=","
                    displayType="text"
                    renderText={(value) => (
                      <Typography className={styles.cartItemPrice}>
                        {value} €
                      </Typography>
                    )}
                  />
                  <Typography className={styles.cartItemQuantity}>
                    ×{cartItem.quantity}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
        <Box className={styles.summaryContainer}>
          <Typography className={styles.summaryText}>
            {translate(translations, 'CART_INFO.TOTAL_COST')}
          </Typography>
          <NumericFormat
            value={1334.96}
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="lakh"
            thousandSeparator=" "
            decimalSeparator=","
            displayType="text"
            renderText={(value) => (
              <Typography className={styles.summaryPrice}>{value} €</Typography>
            )}
          />
        </Box>
        <Box className={styles.btnContainer}>
          <Button
            onClick={() => navigate(routes.checkoutOrder)}
            className={styles.orderBtn}
          >
            {translate(translations, 'CART_INFO.ORDER_BUTTON')}
          </Button>
          <Button
            onClick={() => navigate(routes.checkout)}
            variant={ButtonVariant.outline}
            className={styles.cartBtn}
          >
            {translate(translations, 'CART_INFO.CART_BUTTON')}
          </Button>
        </Box>
      </Menu>
    </>
  );
};

export default CartInfo;
