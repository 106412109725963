import React from 'react';
import styles from './MyGarage.module.scss';
import { Box, Radio, Typography } from '@mui/material';
import { ReactSVG } from 'react-svg';
import TextInput from '../../../common/Input/TextInput/TextInput';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonVariant } from '../../../common/Button/Button';
import { routes } from '../../../../config/routes/routes';
import { useRecoilValue } from 'recoil';
import { translationsState } from '../../../../atoms/translation/translation.atom';
import { translate } from '../../../../utils/translation/translation';
import cx from 'classnames';
import refreshIcon from '../../../../assets/icons/refresh.svg';
import deleteIcon from '../../../../assets/icons/delete.svg';
import chevronRightIcon from '../../../../assets/icons/chevron-right.svg';
import saveIcon from '../../../../assets/icons/save.svg';
import infoIcon from '../../../../assets/icons/info.svg';
import leftIcon from '../../../../assets/icons/chevron-left.svg';

type Props = {
  listContainerClassName?: string;
  onTitleClick?: () => void;
};

const fakeCars = [
  {
    id: 1,
    name: 'Honda Civic XI (FE,FL) XI Sedanas',
    description:
      '1.5 Turbo (FE1) (95 kW / 129 AG) (08.2021 - until the end of dawn and more)',
  },
  {
    id: 2,
    name: 'Honda Civic XI (FE,FL) XI Sedanas',
    description:
      '1.5 Turbo (FE1) (95 kW / 129 AG) (08.2021 - until the end of dawn and more)',
  },
  {
    id: 3,
    name: 'Honda Civic XI (FE,FL) XI Sedanas',
    description:
      '1.5 Turbo (FE1) (95 kW / 129 AG) (08.2021 - until the end of dawn and more)',
  },
  {
    id: 4,
    name: 'Honda Civic XI (FE,FL) XI Sedanas',
    description:
      '1.5 Turbo (FE1) (95 kW / 129 AG) (08.2021 - until the end of dawn and more)',
  },
  {
    id: 5,
    name: 'Honda Civic XI (FE,FL) XI Sedanas',
    description:
      '1.5 Turbo (FE1) (95 kW / 129 AG) (08.2021 - until the end of dawn and more)',
  },
  {
    id: 6,
    name: 'Honda Civic XI (FE,FL) XI Sedanas',
    description:
      '1.5 Turbo (FE1) (95 kW / 129 AG) (08.2021 - until the end of dawn and more)',
  },
  {
    id: 7,
    name: 'Honda Civic XI (FE,FL) XI Sedanas',
    description:
      '1.5 Turbo (FE1) (95 kW / 129 AG) (08.2021 - until the end of dawn and more)',
  },
  {
    id: 8,
    name: 'Honda Civic XI (FE,FL) XI Sedanas',
    description:
      '1.5 Turbo (FE1) (95 kW / 129 AG) (08.2021 - until the end of dawn and more)',
  },
  {
    id: 9,
    name: 'Honda Civic XI (FE,FL) XI Sedanas',
    description:
      '1.5 Turbo (FE1) (95 kW / 129 AG) (08.2021 - until the end of dawn and more)',
  },
];

const MyGarage = ({ listContainerClassName, onTitleClick }: Props) => {
  const translations = useRecoilValue(translationsState);
  const navigate = useNavigate();

  return (
    <>
      <Box className={styles.infoContainer}>
        <Box
          className={cx(styles.titleContainer, {
            [styles.button]: !!onTitleClick,
          })}
          onClick={onTitleClick}
        >
          {onTitleClick && <ReactSVG src={leftIcon} />}
          <Typography className={styles.title}>
            {translate(translations, 'MY_GARAGE.TITLE')}
          </Typography>
        </Box>

        <Box className={styles.infoPopout}>
          <ReactSVG src={infoIcon} />
          <Typography className={styles.infoText}>
            {translate(translations, 'MY_GARAGE.INFO_TEXT')}
          </Typography>
        </Box>
      </Box>

      <Box className={cx(styles.carListContainer, listContainerClassName)}>
        {fakeCars.map((car) => (
          <Box key={car.id} className={styles.car}>
            <Radio />
            <Box className={styles.carInfo}>
              <Typography className={styles.carName}>{car.name}</Typography>
              <Typography className={styles.carDescription}>
                {car.description}
              </Typography>
            </Box>
            <Box className={styles.actionsContainer}>
              <ReactSVG className={styles.deleteBtn} src={deleteIcon} />
              <ReactSVG className={styles.goBtn} src={chevronRightIcon} />
            </Box>
          </Box>
        ))}
      </Box>

      <Box className={styles.vinContainer}>
        <TextInput
          className={styles.vinInput}
          label={translate(translations, 'MY_GARAGE.VIN_INPUT_LABEL')}
        />
        <Button variant={ButtonVariant.secondary}>
          <ReactSVG src={refreshIcon} />
        </Button>
        <Button>
          <ReactSVG src={saveIcon} />
        </Button>
      </Box>

      <Button
        wrapperClassName={styles.btn}
        onClick={() => navigate(routes.profile.vehicles)}
      >
        {translate(translations, 'MY_GARAGE.BUTTON')}
      </Button>
    </>
  );
};

export default MyGarage;
