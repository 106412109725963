import React, { forwardRef } from 'react';
import styles from './DropdownItem.module.scss';
import cx from 'classnames';
import { Box, Typography } from '@mui/material';
import { ReactSVG } from 'react-svg';
import downIcon from '../../../../assets/icons/down.svg';
import upIcon from '../../../../assets/icons/up.svg';
import leftIcon from '../../../../assets/icons/chevron-left.svg';
import rightIcon from '../../../../assets/icons/chevron-right.svg';

type Props = {
  darken?: boolean;
  isOpen?: boolean;
  showChevron?: boolean;
  horizontalChevron?: boolean;
  isBack?: boolean;
  indent?: boolean;
  name: string;
  description?: string;
  icon?: React.ReactNode;
  nameClassName?: string;
  onClick: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

const DropdownItem = forwardRef(
  (
    {
      darken = false,
      isOpen = false,
      showChevron = true,
      horizontalChevron = false,
      isBack = false,
      indent = false,
      name,
      description,
      icon,
      nameClassName,
      onClick,
      onMouseEnter,
      onMouseLeave,
    }: Props,
    ref,
  ) => {
    return (
      <Box
        ref={ref}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={cx(styles.dropdownItem, {
          [styles.darken]: darken,
          [styles.indent]: indent,
          [styles.open]: isOpen,
        })}
      >
        {showChevron && isBack && (
          <ReactSVG className={styles.chevronBack} src={leftIcon} />
        )}

        <Box className={styles.contentContainer}>
          {!!icon && typeof icon === 'string' ? (
            <ReactSVG src={icon} className={styles.icon} />
          ) : (
            icon
          )}
          <Box className={styles.nameContainer}>
            <Typography className={cx(styles.name, nameClassName)}>
              {name}
            </Typography>
            <Typography className={styles.description}>
              {description}
            </Typography>
          </Box>
        </Box>

        {showChevron && !isBack && (
          <ReactSVG
            className={styles.chevron}
            src={horizontalChevron ? rightIcon : isOpen ? downIcon : upIcon}
          />
        )}
      </Box>
    );
  },
);
DropdownItem.displayName = 'DropdownItem';

export default DropdownItem;
