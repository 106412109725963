import React, { useMemo, useRef, useState } from 'react';
import styles from './SubCategoryItem.module.scss';
import { Paper, Popper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../../config/routes/routes';
import SubCategoryList from '../SubCategoryList/SubCategoryList';
import { useWindowWidth } from '@react-hook/window-size';
import DropdownItem from '../DropdownItem/DropdownItem';

export type SubCategory = {
  id: number;
  name: string;
  icon: string;
  items?: SubCategoryItem[];
};

export type SubCategoryItem = {
  name: string;
};

type Props = {
  subCategory: SubCategory;
  onClick?: () => void;
};

const MOBILE_BREAKPOINT = 1200;

const SubCategoryItem = ({ subCategory, onClick }: Props) => {
  const width = useWindowWidth();
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isMobile = useMemo(() => width < MOBILE_BREAKPOINT, [width]);

  const doesHaveItems = useMemo(
    () => !!subCategory.items?.length,
    [subCategory.items],
  );

  const handleClick = () => {
    if (doesHaveItems && onClick) {
      onClick();
    } else {
      navigate(routes.products);
    }
  };

  return (
    <>
      <DropdownItem
        ref={containerRef}
        name={subCategory.name}
        icon={subCategory.icon}
        isOpen={isMenuOpen}
        horizontalChevron
        darken
        indent
        showChevron={doesHaveItems}
        onMouseEnter={() => doesHaveItems && !isMobile && setIsMenuOpen(true)}
        onMouseLeave={() => setIsMenuOpen(false)}
        onClick={handleClick}
      />

      {!isMobile && doesHaveItems && (
        <Popper
          open={isMenuOpen}
          anchorEl={containerRef?.current}
          placement="right-start"
          disablePortal
          onMouseEnter={() => setIsMenuOpen(true)}
          onMouseLeave={() => setIsMenuOpen(false)}
        >
          <Paper
            style={{
              boxShadow: 'none',
            }}
            className={styles.menu}
          >
            <SubCategoryList
              subCategory={subCategory}
              onTitleClick={() => setIsMenuOpen(false)}
            />
          </Paper>
        </Popper>
      )}
    </>
  );
};

export default SubCategoryItem;
