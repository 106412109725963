import React from 'react';
import styles from './AnnouncementBar.module.scss';
import { Box, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { translationsState } from '../../../atoms/translation/translation.atom';
import { translate } from '../../../utils/translation/translation';

const AnnouncementBar = () => {
  const translations = useRecoilValue(translationsState);

  return (
    <Box className={styles.announcementBar}>
      <Typography className={styles.announcementText}>
        {translate(translations, 'ANNOUNCEMENT_BAR.TITLE')}
      </Typography>
    </Box>
  );
};

export default AnnouncementBar;
