import React from 'react';
import styles from './Modal.module.scss';
import { Box, IconButton, Modal as MUIModal } from '@mui/material';
import cx from 'classnames';
import { ReactSVG } from 'react-svg';
import closeIcon from '../../../assets/icons/close.svg';

export type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  className?: string;
  wrapperClassName?: string;
};

const Modal = ({
  isOpen,
  onClose,
  children,
  wrapperClassName,
  className,
}: ModalProps) => {
  return (
    <MUIModal open={isOpen} onClose={onClose}>
      <Box className={cx(styles.modalContentContainer, wrapperClassName)}>
        <Box className={cx(styles.modalContent, className)}>
          <>
            {children}
            <IconButton className={styles.closeBtn} onClick={onClose}>
              <ReactSVG src={closeIcon} />
            </IconButton>
          </>
        </Box>
      </Box>
    </MUIModal>
  );
};

export default Modal;
