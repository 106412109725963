import React, { useEffect, useRef, useState } from 'react';
import styles from './PartCategoryDropdown.module.scss';
import { Box, Menu, Typography } from '@mui/material';
import { ReactSVG } from 'react-svg';
import { useLocation } from 'react-router-dom';
import carIcon from '../../../assets/icons/car.svg';
import downIcon from '../../../assets/icons/down.svg';
import upIcon from '../../../assets/icons/up.svg';
import brakeIcon from '../../../assets/icons/brakes.svg';
import truckIcon from '../../../assets/icons/truck.svg';
import CategoryItem, { Category } from './CategoryItem/CategoryItem';

const categories: Category[] = [
  {
    id: 1,
    name: 'Car parts',
    icon: carIcon,
    subCategories: [
      {
        id: 1,
        name: 'Engine oil',
        icon: brakeIcon,
        items: [
          { name: 'Automatic transmissions fluids' },
          { name: 'Engine coolant' },
          { name: 'Engine oil' },
          { name: 'Gearbox oil and transmissions fluid' },
        ],
      },
      {
        id: 2,
        name: 'Tyres',
        icon: brakeIcon,
        items: [
          { name: 'Automatic transmissions fluids' },
          { name: 'Engine coolant' },
          { name: 'Engine oil' },
          { name: 'Gearbox oil and transmissions fluid' },
        ],
      },
      { id: 3, name: 'Body', icon: brakeIcon },
    ],
  },
  {
    id: 2,
    name: 'Truck parts',
    icon: truckIcon,
  },
];

const PartCategoryDropdown = () => {
  const location = useLocation();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openCategoryId, setOpenCategoryId] = useState<number>();

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  const handleCategoryClick = (id: number) => {
    openCategoryId === id
      ? setOpenCategoryId(undefined)
      : setOpenCategoryId(id);
  };

  return (
    <>
      <Box
        ref={containerRef}
        onClick={() => setIsMenuOpen((prev) => !prev)}
        className={styles.dropdownContainer}
      >
        <Box className={styles.selectedItem}>
          <ReactSVG src={categories[0].icon} className={styles.icon} />
          <Typography className={styles.title}>{categories[0].name}</Typography>
        </Box>
        <ReactSVG
          src={isMenuOpen ? upIcon : downIcon}
          className={styles.icon}
        />
      </Box>

      <Menu
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        anchorEl={containerRef?.current}
        className={styles.menu}
        slotProps={{
          paper: {
            style: {
              width: containerRef.current
                ? containerRef.current.offsetWidth
                : undefined,
            },
          },
        }}
      >
        {categories.map((category) => (
          <CategoryItem
            key={category.id}
            category={category}
            onClick={() => handleCategoryClick(category.id)}
            isOpen={openCategoryId === category.id}
          />
        ))}
      </Menu>
    </>
  );
};

export default PartCategoryDropdown;
