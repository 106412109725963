import React, { useState } from 'react';
import styles from './LoginModal.module.scss';
import { Box, Typography } from '@mui/material';
import Modal from '../../common/Modal/Modal';
import TextInput from '../../common/Input/TextInput/TextInput';
import PasswordInput from '../../common/Input/PasswordInput/PasswordInput';
import CheckboxInput from '../../common/Input/CheckboxInput/CheckboxInput';
import Button from '../../common/Button/Button';
import cx from 'classnames';
import { useRecoilValue } from 'recoil';
import { translationsState } from '../../../atoms/translation/translation.atom';
import { translate } from '../../../utils/translation/translation';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const LoginModal = ({ isOpen, onClose }: Props) => {
  const translations = useRecoilValue(translationsState);
  const [isRegister, setIsRegister] = useState(false);

  return (
    <Modal isOpen={isOpen} onClose={onClose} className={styles.modal}>
      <form className={styles.loginForm}>
        <Typography className={styles.title}>
          {isRegister
            ? translate(translations, 'LOGIN_MODAL.REGISTER_TITLE')
            : translate(translations, 'LOGIN_MODAL.LOGIN_TITLE')}
        </Typography>

        <Box className={styles.inputContainer}>
          {isRegister && (
            <>
              <TextInput
                name="name"
                label={translate(translations, 'LOGIN_MODAL.NAME_INPUT_LABEL')}
              />
              <TextInput
                name="surname"
                label={translate(
                  translations,
                  'LOGIN_MODAL.SURNAME_INPUT_LABEL',
                )}
              />
            </>
          )}
          <TextInput
            name="email"
            required
            label={translate(translations, 'LOGIN_MODAL.EMAIL_INPUT_LABEL')}
          />
          <PasswordInput
            name="password"
            label={translate(translations, 'LOGIN_MODAL.PASSWORD_INPUT_LABEL')}
          />
        </Box>
        <Box
          className={cx(styles.checkBoxContainer, {
            [styles.register]: isRegister,
          })}
        >
          {isRegister ? (
            <>
              <Typography className={styles.actionText}>
                Please see our{' '}
                <span className={styles.highlighted}>Privacy Policy</span>.
              </Typography>
              <CheckboxInput
                name="notificationConfirm"
                label={
                  <>
                    Yes, I agree to receive the PartEvo newsletter with
                    personalized offers, special discounts, requests to
                    participate in...{' '}
                    <span className={styles.highlighted}>Daugiau</span>
                  </>
                }
              />
            </>
          ) : (
            <>
              <CheckboxInput name="memorize" label="Memorize" />
              <Typography className={cx(styles.actionText, styles.highlighted)}>
                {translate(translations, 'LOGIN_MODAL.FORGOT_PASSWORD')}
              </Typography>
            </>
          )}
        </Box>

        <Button wrapperClassName={styles.loginBtn}>
          {isRegister
            ? translate(translations, 'LOGIN_MODAL.REGISTER_BUTTON')
            : translate(translations, 'LOGIN_MODAL.LOGIN_BUTTON')}
        </Button>

        {!isRegister && (
          <Typography className={styles.description}>
            If you don&apos;t have an PartEvo account,{' '}
            <span
              className={styles.highlighted}
              onClick={() => setIsRegister(true)}
            >
              register
            </span>{' '}
            now.
          </Typography>
        )}
      </form>
    </Modal>
  );
};

export default LoginModal;
