import React from 'react';
import Section from '../Section/Section';
import styles from './FeaturedBlog.module.scss';
import ArticleCard from '../ArticleCard/ArticleCard';
import { useRecoilValue } from 'recoil';
import { translationsState } from '../../../atoms/translation/translation.atom';
import { translate } from '../../../utils/translation/translation';

const fakeBlogs = [
  {
    id: 1,
    thumbnail: 'https://via.placeholder.com/500',
    title: 'Blog name number 1 can take even few rows',
    excerpt:
      // eslint-disable-next-line max-len
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque lacinia at enim in ornare. Vestibulum in nulla vulputate, tristique urna a, efficitur risus. Nam fringilla augue rutrum, lobortis quam in, feugiat sapien. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.',
  },
  {
    id: 2,
    thumbnail: 'https://via.placeholder.com/500',
    title: 'Blog name number 2 can take even few rows',
    excerpt:
      // eslint-disable-next-line max-len
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque lacinia at enim in ornare.',
  },
  {
    id: 3,
    thumbnail: 'https://via.placeholder.com/500',
    title:
      'Blog name number 3 can take even few rows or not I dont know for sure',
    excerpt:
      // eslint-disable-next-line max-len
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque lacinia at enim in ornare. Vestibulum in nulla vulputate, tristique urna a, efficitur risus. Nam fringilla augue rutrum, lobortis quam in, feugiat sapien. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.',
  },
];

const FeaturedBlogs = () => {
  const translations = useRecoilValue(translationsState);

  return (
    <Section
      title={translate(translations, 'FEATURED_BLOGS.TITLE')}
      wrapperClassName={styles.section}
      className={styles.featuredBlogs}
      accent
    >
      {fakeBlogs.map((blog) => (
        <ArticleCard key={blog.id} {...blog} />
      ))}
    </Section>
  );
};

export default FeaturedBlogs;
