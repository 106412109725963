import React from 'react';
import { Box, Typography } from '@mui/material';
import styles from './CTA.module.scss';
import ctaImage from '../../../assets/cta-image.webp';
import ctaPlaceholder from '../../../assets/cta-placeholder.svg';
import ctaPlaceholderMobile from '../../../assets/cta-placeholder-mobile.svg';
import Button, { ButtonVariant } from '../../common/Button/Button';
import cx from 'classnames';
import { useWindowWidth } from '@react-hook/window-size';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { translationsState } from '../../../atoms/translation/translation.atom';
import { translate } from '../../../utils/translation/translation';
import { routes } from '../../../config/routes/routes';

const MOBILE_BREAKPOINT = 900;

export const CTA = () => {
  const width = useWindowWidth();
  const translations = useRecoilValue(translationsState);
  const navigate = useNavigate();

  return (
    <Box className={styles.ctaContainer}>
      <Box className={styles.innerContainer}>
        <img src={ctaImage} className={styles.ctaImage} />
        <Box className={styles.placeholderContainer}>
          <img
            src={
              width > MOBILE_BREAKPOINT ? ctaPlaceholder : ctaPlaceholderMobile
            }
            className={styles.ctaPlaceholder}
          />
          <Box className={styles.textContainer}>
            <Box className={styles.lineContainer}>
              <Typography className={styles.firstLine}>
                {translate(translations, 'CTA.FIRST_LINE_MAIN')}
              </Typography>
              <Typography className={cx(styles.firstLine, styles.buyNow)}>
                {translate(translations, 'CTA.FIRST_LINE_SECONDARY')}
              </Typography>
            </Box>
            <Box className={styles.lineContainer}>
              <Typography className={styles.secondLine}>
                {translate(translations, 'CTA.SECOND_LINE_MAIN')}
              </Typography>
              <Typography className={cx(styles.secondLine, styles.cta)}>
                {translate(translations, 'CTA.SECOND_LINE_SECONDARY')}
              </Typography>
              <Button
                variant={ButtonVariant.secondary}
                className={styles.buttonCta}
                onClick={() => navigate(routes.catalog)}
              >
                {translate(translations, 'CTA.BUTTON')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CTA;
