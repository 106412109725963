import React, { ReactNode } from 'react';
import styles from './Section.module.scss';
import { Box, Typography } from '@mui/material';
import cx from 'classnames';

export type SectionProps = {
  title?: string;
  titleClassName?: string;
  className?: string;
  wrapperClassName?: string;
  accent?: boolean;
};

type Props = {
  children: ReactNode;
} & SectionProps;

export const Section = ({
  title,
  titleClassName,
  className,
  wrapperClassName,
  accent = false,
  children,
}: Props) => {
  return (
    <Box
      className={cx(styles.sectionContainer, wrapperClassName, {
        [styles.accent]: accent,
      })}
    >
      {title && (
        <Typography className={cx(styles.title, titleClassName)}>
          {title}
        </Typography>
      )}
      <Box className={cx(styles.contentContainer, className)}>{children}</Box>
    </Box>
  );
};

export default Section;
