import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import styles from './Subscribe.module.scss';
import Button, { ButtonVariant } from '../../common/Button/Button';
import checkIcon from '../../../assets/icons/check.svg';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { routes } from '../../../config/routes/routes';
import { useRecoilValue } from 'recoil';
import { translationsState } from '../../../atoms/translation/translation.atom';
import { translate } from '../../../utils/translation/translation';

export const Subscribe = () => {
  const translations = useRecoilValue(translationsState);
  const [isChecked, setIsChecked] = useState(false);

  return (
    <Box className={styles.subscribeContainer}>
      <Box className={styles.inner}>
        <Box className={styles.textContainer}>
          <Typography
            className={styles.title}
            dangerouslySetInnerHTML={{
              __html: translate(translations, 'SUBSCRIBE.TITLE'),
            }}
          />
          <Typography className={styles.subtitle}>
            {translate(translations, 'SUBSCRIBE.SUBTITLE')}
          </Typography>
        </Box>
        <Box className={styles.actionContainer}>
          <Box className={styles.inputContainer}>
            <input
              placeholder={translate(
                translations,
                'SUBSCRIBE.EMAIL_INPUT_PLACEHOLDER',
              )}
            />
            <Button className={styles.button} variant={ButtonVariant.primary}>
              {translate(translations, 'SUBSCRIBE.BUTTON')}
            </Button>
          </Box>
          <Box className={styles.agreementContainer}>
            <Box
              className={cx(styles.checkbox, { [styles.checked]: isChecked })}
              onClick={() => setIsChecked((prev) => !prev)}
            >
              {isChecked && <img src={checkIcon} />}
            </Box>
            <Typography className={styles.explanation}>
              {translate(translations, 'SUBSCRIBE.CHECKBOX_LABEL')}
              <Link to={routes.homepage}>
                {translate(translations, 'SUBSCRIBE.CHECKBOX_READ_MORE')}
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Subscribe;
