import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import styles from './SearchInput.module.scss';
import infoIcon from '../../../assets/icons/info.svg';
import { useRecoilValue } from 'recoil';
import { translationsState } from '../../../atoms/translation/translation.atom';
import { translate } from '../../../utils/translation/translation';
import searchIcon from '../../../assets/icons/search.svg';
import { ReactSVG } from 'react-svg';
import Modal from '../../common/Modal/Modal';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../config/routes/routes';

const helpInfo = [
  { name: 'Užklausos tipas', value: 'Pavyzdys' },
  { name: 'Atsarginės dalys', value: 'Valytuvai' },
  { name: 'Atsarginės dalys + Gamintojas', value: 'Valytuvai MAGNETI MARELLI' },
  {
    name: 'Atsarginės dalys + Automobilio gamintojas',
    value: 'Valytuvai PORSCHE',
  },
  {
    name: 'Atsarginės dalys + Prekės numeris',
    value: 'Valytuvai 000723136001',
  },
  {
    name: 'Prekės numeris',
    value: '3 397 112 202',
  },
  {
    name: 'Prekės numeris + Gamintojas',
    value: '000733500000 MAGNETI MARELLI',
  },
  {
    name: 'OEN',
    value: '1780107',
  },
  {
    name: 'OEN + Gamintojas',
    value: '1537393 BOSCH',
  },
];

export const SearchInput = () => {
  const translations = useRecoilValue(translationsState);
  const [helpOpen, setHelpOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <Box className={styles.searchInputContainer}>
        <Box className={styles.searchInputContainerInner}>
          <input
            className={styles.input}
            placeholder={translate(translations, 'SEARCH_INPUT.PLACEHOLDER')}
          />
          <Box className={styles.needHelp} onClick={() => setHelpOpen(true)}>
            {translate(translations, 'SEARCH_INPUT.NEED_HELP')}
            <ReactSVG src={infoIcon} />
          </Box>
        </Box>
        <Box
          className={styles.button}
          onClick={() => navigate(routes.products)}
        >
          <ReactSVG src={searchIcon} />
        </Box>
      </Box>

      <Modal
        isOpen={helpOpen}
        onClose={() => setHelpOpen(false)}
        className={styles.helpModal}
      >
        <Typography className={styles.title}>
          {translate(translations, 'SEARCH_INPUT.HELP_MODAL_TITLE')}
        </Typography>

        <Box className={styles.infoContainer}>
          {helpInfo.map((info) => (
            <Box key={info.name} className={styles.info}>
              <Typography className={styles.infoName}>{info.name}</Typography>
              <Typography className={styles.infoValue}>{info.value}</Typography>
            </Box>
          ))}
        </Box>
      </Modal>
    </>
  );
};

export default SearchInput;
